<template>
    <div id="services">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <HeadSections class="services-img"
    :TitlePage="$route.name"
    :TitleSec="this.$i18n.locale == 'ar' ?`تلبي احتياجاتك وتجعل حياتك أسهل.`:`Meet your needs and make your life easier.`"
    :PathPageFrom="$t('Home')"
    :PathPageTo="$t('Services')" />
    <div class="container" >
    <IndexService />
    </div>
  </div>
  </template>
  <script>
  import { defineAsyncComponent } from 'vue'
  export default {
  name: 'Services Page',
  components: {
    HeadSections: defineAsyncComponent( () => import('@/components/Global/HeadSections.vue') ),
    IndexService: defineAsyncComponent( () => import('@/components/Services/IndexService.vue') ),
  },
  }
  </script>
